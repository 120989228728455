var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "DashboardAuditLog" },
    [
      _c(
        "div",
        {
          staticClass:
            "row row--align-center row--justify-between width-100 mb-17"
        },
        [
          _c("label", { staticClass: "row row--width-auto fs-18 fw-med" }, [
            _vm._v("\n      Logs (" + _vm._s(_vm.logsTotal || 0) + ")\n    ")
          ]),
          _c(
            "div",
            { staticClass: "row row--align-center row--width-auto" },
            [
              _c("v-select", {
                staticClass: "DashboardAuditLog__select mr-12",
                attrs: {
                  "aria-label": "Select input to choose search filter",
                  clearable: false,
                  options: _vm.filterOptions
                },
                on: {
                  input: function($event) {
                    return _vm.searchLogs(1)
                  }
                },
                model: {
                  value: _vm.selectedFilter,
                  callback: function($$v) {
                    _vm.selectedFilter = $$v
                  },
                  expression: "selectedFilter"
                }
              }),
              _c("base-date-picker", {
                staticClass: "mr-12",
                attrs: { placeholder: "From Date" },
                model: {
                  value: _vm.fromDate,
                  callback: function($$v) {
                    _vm.fromDate = $$v
                  },
                  expression: "fromDate"
                }
              }),
              _c("base-date-picker", {
                staticClass: "mr-12",
                attrs: { placeholder: "To Date" },
                model: {
                  value: _vm.toDate,
                  callback: function($$v) {
                    _vm.toDate = $$v
                  },
                  expression: "toDate"
                }
              }),
              _c(
                "base-button",
                {
                  staticClass: "bg-white fc-blue",
                  on: {
                    click: function($event) {
                      return _vm.searchLogs(1)
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fa fa-search-left" }),
                  _vm._v("\n        Search\n      ")
                ]
              )
            ],
            1
          )
        ]
      ),
      _c(_vm.currentAuditComponent, {
        tag: "component",
        attrs: { logs: _vm.logs }
      }),
      _c("base-pagination", {
        staticClass: "mt-22",
        attrs: {
          "item-count": _vm.logsTotal,
          page: _vm.page,
          "request-limit": _vm.requestLimit
        },
        on: { paginate: _vm.searchQBSyncLogs }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }