<template>
  <div class="Table">
    <div class="Table__head-wrapper Table__head-wrapper row">
      <label class="Table__header-lbl Table__header-lbl--BasePaymentsAudit">Date</label>
      <label class="Table__header-lbl Table__header-lbl--BasePaymentsAudit">Posted Date</label>
      <label class="Table__header-lbl Table__header-lbl--BasePaymentsAudit">Batch Date</label>
      <label class="Table__header-lbl Table__header-lbl--BasePaymentsAudit">Invoice #</label>
      <label class="Table__header-lbl Table__header-lbl--BasePaymentsAudit">Notes</label>
    </div>

    <div
      v-for="(log, index) in logs"
      :key="index"
      class="Table__item"
    >
      <div class="Table__item-wrapper Table__item-wrapper--BasePaymentsAudit row">
        <!-- DATE -->
        <div class="column">
          <label class="fs-15 fc-light">
            <time
              class="fs-15"
              :datetime="log.updated_at | datetime('YY-MM-DD hh:mm:ssZ')"
            >
              {{ log.updated_at | datetime('MM/DD/YY') }}
            </time>
          </label>
        </div>

        <!-- POSTED DATE -->
        <div class="column">
          <label class="fs-15 fc-light">
            <time
              class="fs-15"
              :datetime="log.original_created_at | datetime('YY-MM-DD hh:mm:ssZ')"
            >
              {{ log.original_created_at | datetime('MM/DD/YY') }}
            </time>
          </label>
        </div>

        <!-- BATCH DATE -->
        <div class="column">
          <label class="fs-15 fc-light">
            <time
              class="fs-15"
              :datetime="log.batch_date | datetime('YY-MM-DD hh:mm:ssZ')"
            >
              {{ log.batch_date | datetime('MM/DD/YY') }}
            </time>
          </label>
        </div>

        <!-- INVOICE # -->
        <div class="column">
          <label
            class="fs-15 fc-light"
            :id="`Testing__AuditLogId-${index}`"
          >
            {{ log.display_id }}
          </label>
        </div>

        <!-- NOTES -->
        <div class="column">
          <label
            class="fs-15 fc-light"
            :id="`Testing__AuditLogMessage-${index}`"
            v-html="log.MESSAGE.replace(/\n/g, '<br>')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BasePaymentsAuditTable',

  props: {
    logs: {
      type: Array,
      required: false,
      default: null
    }
  }
}
</script>

<style lang="sass">
@import '../styles/tables'
</style>
