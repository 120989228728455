<template>
  <div class="DashboardAuditLog">
    <div class="row row--align-center row--justify-between width-100 mb-17">
      <label class="row row--width-auto fs-18 fw-med">
        Logs ({{ logsTotal || 0 }})
      </label>

      <div class="row row--align-center row--width-auto">
        <v-select
          @input="searchLogs(1)"
          v-model="selectedFilter"
          aria-label="Select input to choose search filter"
          class="DashboardAuditLog__select mr-12"
          :clearable="false"
          :options="filterOptions"
        />
        <base-date-picker
          v-model="fromDate"
          :placeholder="'From Date'"
          class="mr-12"
        />
        <base-date-picker
          v-model="toDate"
          :placeholder="'To Date'"
          class="mr-12"
        />
        <base-button
          @click="searchLogs(1)"
          class="bg-white fc-blue"
        >
          <i class="fa fa-search-left" />
          Search
        </base-button>
      </div>
    </div>

    <component
      :is="currentAuditComponent"
      :logs="logs"
    />

    <base-pagination
      @paginate="searchQBSyncLogs"
      class="mt-22"
      :item-count="logsTotal"
      :page="page"
      :request-limit="requestLimit"
    />
  </div>
</template>

<script>
// Packages
import moment from 'moment'
// Helpers
import {
  DebtorPayment,
  QuickbooksSync
} from '../../utils/api'
// Components
import BaseButton from '../../components/base-button.vue'
import BaseDatePicker from '../../components/base-date-picker.vue'
import BasePagination from '../../components/base-pagination.vue'
import BasePaymentsAuditTable from '../../components/base-payments-audit-table.vue'
import BaseQuickbooksSyncAuditTable from '../../components/base-quickbooks-sync-audit-table.vue'

export default {
  name: 'DashboardAuditLog',

  components: {
    BaseButton,
    BaseDatePicker,
    BasePagination,
    BasePaymentsAuditTable,
    BaseQuickbooksSyncAuditTable
  },

  async created () {
    await this.searchPaymentLogs()
  },

  data () {
    return {
      allLogs: [],
      filterOptions: [
        'Debtor Payments',
        'QB Sync'
      ],
      fromDate: moment(new Date()).subtract(7, 'days').toDate(),
      loadedPaymentLogs: false,
      logs: null,
      logsTotal: null,
      page: 1,
      toDate: new Date(),
      requestLimit: 10,
      selectedFilter: 'Debtor Payments'
    }
  },

  computed: {
    currentAuditComponent () {
      if (this.selectedFilter === 'Debtor Payments') return 'base-payments-audit-table'
      return 'base-quickbooks-sync-audit-table'
    },
  },

  methods: {

    async searchLogs (page = null) {
      return (this.selectedFilter === 'Debtor Payments')
        ? this.searchPaymentLogs(page)
        : this.searchQBSyncLogs()
    },

    async searchPaymentLogs (page = null) {
      this.CLog('got to here')
      // validate the dates
      if (!moment(this.fromDate).isBefore(moment(this.toDate))) {
        return this.alertInfo('The "from" date must be before the "to" date')
      }

      if (page) this.page = page

      /**
       * Due to realtime calculations that need all history data,
       * all API requests will pull all history data.
       * We will need to paginate it on the frontend.
      */
      if (this.page === 1 || !this.loadedPaymentLogs) {
        this.progressStart()

        try {
          const logs = (await DebtorPayment.history({
            forceInclude: 'invoice',
            gt___created_at: this.fromDate,
            is_created: false,
            lt___created_at: this.toDate,
            returnWithDetails: true,
            sort_by: 'updated_at',
            sort_direction: 'DESC'
          })).data

          this.loadedPaymentLogs = true
          this.allLogs = logs.rows
          this.logsTotal = logs.count
          this.progressFinish()
        }
        catch (error) {
          this.captureSentryEvent(
            'Dashboard Employee Audit Log "Search Payment Logs"',
            {
              config: error.config,
              data: this.$data,
              details: error,
              props: this.$props,
              response: error.response,
            }
          )
          this.CError(error)
          this.requestFailure({ message: 'There was an issue getting audit logs' })
        }
      }

      const begin = this.page ? (this.page - 1) * this.requestLimit : 0
      const end = begin + this.requestLimit
      this.logs = this.allLogs.slice(begin, end)
    },

    async searchQBSyncLogs () {
      let logs
      try {
        logs = (await QuickbooksSync.queryList({
          gt___created_at: moment(this.date).startOf('day').toDate(),
          lt___created_at: moment(this.date).endOf('day').toDate()
        })).data
      }
      catch (error) {
        this.captureSentryEvent(
          'Dashboard Employee Audit Log "Search QB Sync Logs"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting Quickbooks Sync' })
      }

      this.logs = logs.rows.map(log => {
        let displayType = log.entry_type
        if (log.entry_type === 'balance') displayType = 'Balance Adjustment'
        else if (log.entry_type === 'client') displayType = 'Client'
        else if (log.entry_type === 'debtorpayment-lockbox') displayType = 'Debtor Payment (Check)'
        else if (log.entry_type === 'debtorpayment-ach') displayType = 'Debtor Payment (ACH)'
        else if (log.entry_type.includes('clientpayment')) {
          displayType = `Client Payment (Batch: ${log.entry_type.split('-')[1]})`
        }

        log.displayType = displayType
        return log
      })
      this.logsTotal = logs.count
    }
  }
}
</script>

<style lang="sass">
@import '../../styles/tables'
.DashboardAuditLog

  &__select
    width: rem(195px)
</style>