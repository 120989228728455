<template>
  <div class="Table">
    <div class="Table__head-wrapper Table__head-wrapper row">
      <label class="Table__header-lbl Table__header-lbl--BaseQBSyncAudit">Date</label>
      <label class="Table__header-lbl Table__header-lbl--BaseQBSyncAudit">Type</label>
      <label class="Table__header-lbl Table__header-lbl--BaseQBSyncAudit">Notes</label>
    </div>

    <div
      v-for="(log, index) in logs"
      :key="index"
      class="Table__item"
    >
      <div class="Table__item-wrapper Table__item-wrapper--BaseQBSyncAudit row row--align-center">
        <!-- DATE -->
        <div class="column">
          <label class="fs-15 fc-light">
            <time
              class="fs-15"
              :datetime="log.updated_at | datetime('YY-MM-DD hh:mm:ssZ')"
            >
              {{ log.updated_at | datetime('MM/DD/YY') }}
            </time>
          </label>
        </div>

        <!-- TYPE -->
        <div class="column">
          <label class="fs-15">
            {{ log.displayType }}
          </label>
        </div>

        <!-- NOTES -->
        <div class="column">
          <label class="fs-15 fc-light">
            {{ log.completed ? 'Pass' : 'Fail' }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseQuickbooksSyncAuditTable',

  props: {
    logs: {
      type: Array,
      required: false,
      default: null
    }
  }
}
</script>

<style lang="sass">
@import '../styles/tables'
</style>