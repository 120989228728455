var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Table" },
    [
      _vm._m(0),
      _vm._l(_vm.logs, function(log, index) {
        return _c("div", { key: index, staticClass: "Table__item" }, [
          _c(
            "div",
            {
              staticClass:
                "Table__item-wrapper Table__item-wrapper--BasePaymentsAudit row"
            },
            [
              _c("div", { staticClass: "column" }, [
                _c("label", { staticClass: "fs-15 fc-light" }, [
                  _c(
                    "time",
                    {
                      staticClass: "fs-15",
                      attrs: {
                        datetime: _vm._f("datetime")(
                          log.updated_at,
                          "YY-MM-DD hh:mm:ssZ"
                        )
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("datetime")(log.updated_at, "MM/DD/YY")
                          ) +
                          "\n          "
                      )
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "column" }, [
                _c("label", { staticClass: "fs-15 fc-light" }, [
                  _c(
                    "time",
                    {
                      staticClass: "fs-15",
                      attrs: {
                        datetime: _vm._f("datetime")(
                          log.original_created_at,
                          "YY-MM-DD hh:mm:ssZ"
                        )
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("datetime")(
                              log.original_created_at,
                              "MM/DD/YY"
                            )
                          ) +
                          "\n          "
                      )
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "column" }, [
                _c("label", { staticClass: "fs-15 fc-light" }, [
                  _c(
                    "time",
                    {
                      staticClass: "fs-15",
                      attrs: {
                        datetime: _vm._f("datetime")(
                          log.batch_date,
                          "YY-MM-DD hh:mm:ssZ"
                        )
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("datetime")(log.batch_date, "MM/DD/YY")
                          ) +
                          "\n          "
                      )
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "column" }, [
                _c(
                  "label",
                  {
                    staticClass: "fs-15 fc-light",
                    attrs: { id: "Testing__AuditLogId-" + index }
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(log.display_id) + "\n        "
                    )
                  ]
                )
              ]),
              _c("div", { staticClass: "column" }, [
                _c("label", {
                  staticClass: "fs-15 fc-light",
                  attrs: { id: "Testing__AuditLogMessage-" + index },
                  domProps: {
                    innerHTML: _vm._s(log.MESSAGE.replace(/\n/g, "<br>"))
                  }
                })
              ])
            ]
          )
        ])
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "Table__head-wrapper Table__head-wrapper row" },
      [
        _c(
          "label",
          {
            staticClass:
              "Table__header-lbl Table__header-lbl--BasePaymentsAudit"
          },
          [_vm._v("Date")]
        ),
        _c(
          "label",
          {
            staticClass:
              "Table__header-lbl Table__header-lbl--BasePaymentsAudit"
          },
          [_vm._v("Posted Date")]
        ),
        _c(
          "label",
          {
            staticClass:
              "Table__header-lbl Table__header-lbl--BasePaymentsAudit"
          },
          [_vm._v("Batch Date")]
        ),
        _c(
          "label",
          {
            staticClass:
              "Table__header-lbl Table__header-lbl--BasePaymentsAudit"
          },
          [_vm._v("Invoice #")]
        ),
        _c(
          "label",
          {
            staticClass:
              "Table__header-lbl Table__header-lbl--BasePaymentsAudit"
          },
          [_vm._v("Notes")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }